<template>
  <v-card class="mt-3">
    <v-card-title>Zadanie otwarte</v-card-title>
    <v-card-text>
      <p v-html="title"></p>

      <div v-if="summaryMode">
        <v-card color="grey lighten-4" elevation="0">
          <v-card-title>Klucz</v-card-title>
          <v-card-text>
            <p v-html="answers"></p>
            <!-- In OpenExercise value answers is key -->
          </v-card-text>
        </v-card>
      </div>

      <v-alert v-if="!summaryMode" type="info" text dense
        >To zadanie otwarte. Rozwiąż je na kartce. Po zakończeniu rozwiązywania
        zadań poprosimy Cię o ręczne sprawdzenie odpowiedzi z kluczem.</v-alert
      >
    </v-card-text>
  </v-card>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  props: [
    "title",
    "exerciseId",
    "summaryMode",
    "exerciseData",
    "answers",
    "points",
  ],
  data() {
    return {
      answer: "",
    };
  },
  mounted() {
    this.changeContainOpenExercise();

    if (this.summaryMode) {
      for (let i = 0; i < this.points; i++) {
        this.incrementPoints();
      }
    }

    this.reRenderMath();
  },
  methods: {
    ...mapMutations([
      "pushExerciseData",
      "incrementPoints",
      "incrementScore",
      "changeContainOpenExercise",
      "addOpenExercise",
    ]),
    reRenderMath() {
      if (window.MathJax) {
        console.log("rendering mathjax");
        window.MathJax.Hub.Queue(["Typeset", window.MathJax.Hub], () =>
          console.log("done")
        );
      }
    },
  },
  destroyed() {
    this.pushExerciseData(this.answer);
    this.addOpenExercise({
      title: this.title,
      answer: this.answer,
      key: this.answers,
      points: this.points,
    });
  },
};
</script>

<style></style>
