<template>
  <v-card class="mt-3">
    <v-card-title>Wybór wielokrotny</v-card-title>
    <v-card-text>
      <p v-html="title"></p>

      <div v-if="summaryMode">
        <v-alert v-if="!correct" type="error" text>
          Niepoprawna odpowiedź
        </v-alert>
        <v-alert v-else type="success" text> Poprawna odpowiedź </v-alert>
      </div>

      <v-container>
        <v-row v-for="(answer, index) in answers" :key="index">
          <v-checkbox :value="index" v-if="!summaryMode" v-model="checkbox">
            <template v-slot:label
              ><span
                style="display: inline-block"
                v-html="answer.content"
              ></span
            ></template>
          </v-checkbox>

          <v-checkbox :value="index" v-else readonly v-model="checkbox">
            <template v-slot:label
              ><span
                style="display: inline-block"
                v-html="answer.content"
              ></span
            ></template>
          </v-checkbox>
        </v-row>
      </v-container>

      <v-card color="grey lighten-4" elevation="0" v-if="summaryMode">
        <v-card-text>
          <p>Poprawne odpowiedzi:</p>
          <ul>
            <li
              v-for="(correctAnswer, index) in correctAnswers"
              :key="index"
              v-html="correctAnswer"
            ></li>
          </ul>
        </v-card-text>
      </v-card>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  props: ["title", "answers", "exerciseId", "summaryMode", "exerciseData"],
  data() {
    return {
      correctAnswers: [],
      checkbox: [],
      correct: true,
    };
  },
  mounted() {
    function isCorrect(value) {
      return value.isCorrect == true;
    }

    if (this.summaryMode) {
      var filtered = this.answers.filter(isCorrect);

      filtered.forEach((element) => {
        this.correctAnswers.push(element.content);
      });

      this.exerciseData.forEach((element) => {
        if (this.answers[element].isCorrect == false) {
          this.correct = false;
        }
      });

      if (this.correctAnswers.length != this.exerciseData.length) {
        this.correct = false;
      }

      this.incrementPoints();

      if (this.correct) {
        this.incrementScore();
      }

      this.checkbox = this.exerciseData;
    }
    this.reRenderMath();
  },
  methods: {
    ...mapMutations(["pushExerciseData", "incrementPoints", "incrementScore"]),
    reRenderMath() {
      if (window.MathJax) {
        console.log("rendering mathjax");
        window.MathJax.Hub.Queue(["Typeset", window.MathJax.Hub], () =>
          console.log("done")
        );
      }
    },
  },
  destroyed() {
    // if(this.summaryMode == false) {
    this.pushExerciseData(this.checkbox);
    // }
  },
};
</script>

<style>
</style>