<template>
  <v-card class="mt-3">
    <v-card-title>Informacje do zadań</v-card-title>
    <v-card-text v-html="content"> </v-card-text>
  </v-card>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  props: ["content"],
  methods: {
    ...mapMutations(["pushExerciseData"]),
    reRenderMath() {
      if (window.MathJax) {
        console.log("rendering mathjax");
        window.MathJax.Hub.Queue(["Typeset", window.MathJax.Hub], () =>
          console.log("done")
        );
      }
    },
  },
  destroyed() {
    // if(this.summaryMode == false) {
    this.pushExerciseData("info_only");
    // }
  },
  mounted() {
    this.reRenderMath();
  },
};
</script>

<style>
</style>