<template>
  <div>
    <v-alert type="error" v-if="errMessage">
      {{ this.errMessage }}
    </v-alert>
    <v-card @nextCard="nextCard" v-if="!errMessage">
      <v-progress-linear
        color="primary"
        :value="(currentCard / cards.length) * 100"
        v-if="!loading"
      />

      <div v-if="loading">
        <v-progress-linear indeterminate color="primary"></v-progress-linear>
        <v-skeleton-loader type="article, image"></v-skeleton-loader>
      </div>

      <v-card-title>{{ title }}</v-card-title>
      <v-card-text v-if="content" v-html="content" />
      <v-card-text v-if="!content">
        <Exercise
          v-for="exercise in exercises"
          :key="exercise.id"
          :exercise="exercise"
        />
      </v-card-text>
    </v-card>

    <v-btn
      v-if="!isLast"
      @click="nextCard"
      class="float-right mt-2 mb-2"
      color="primary"
      @click.ctrl="downloadJson"
      >Dalej</v-btn
    >
    <v-btn
      v-if="isLast"
      class="float-right mt-2 mb-2"
      :to="{ name: 'Summary', params: { id: id, userId: $route.query.userId } }"
      color="primary"
      >Koniec</v-btn
    >
  </div>
</template>

<script>
import Exercise from "@/components/LearningMode/Exercise.vue";
import db from "@/firebase/init";
import { mapMutations } from "vuex";
import download from "downloadjs";

export default {
  components: { Exercise },
  props: ["id"],
  mounted() {
    this.clearExerciseData();
    let docRef = db.collection("exercises").doc(this.id);
    docRef
      .get()
      .then((doc) => {
        this.cards = doc.data().cards;
        this.loading = false;
        this.nextCard();
        this.changeExercises(doc.data().cards);
      })
      .catch(() => {
        this.throwErrAlert(
          "Niestety, takie ćwiczenie nie istnieje lub nie masz uprawnień, aby je odczytać."
        );
        this.isLast = true;
      });
  },
  data() {
    return {
      errMessage: "",
      cards: [],
      loading: true,
      currentCard: -1,
      title: "",
      content: "",
      type: "content",
      exercises: null,
      isLast: false,
    };
  },
  methods: {
    ...mapMutations(["changeExercises", "clearExerciseData"]),
    nextCard() {
      this.currentCard++;
      if (this.cards[this.currentCard].title) {
        this.title = this.cards[this.currentCard].title;
        this.content = this.cards[this.currentCard].content;
        this.type = "content";
        this.reRenderMath();
      } else {
        this.title = "Ćwiczenie";
        this.content = "";
        this.type = "exercise";
        this.exercises = this.cards[this.currentCard].exercises;
      }

      if (this.cards.length - 1 == this.currentCard) {
        this.isLast = true;
      }
    },
    reRenderMath() {
      this.$nextTick(() => {
        if (window.MathJax) {
          console.log("rendering mathjax");
          window.MathJax.Hub.Queue(["Typeset", window.MathJax.Hub], () =>
            console.log("done")
          );
        }
      });
    },
    throwErrAlert(text) {
      this.errMessage = text;
    },
    downloadJson() {
      const date = new Date();
      const json = JSON.stringify(
        {
          timeCreated: date,
          cards: this.cards,
        },
        null,
        4
      );
      const blob = new Blob([json], { type: "application/json;charset=utf8" });
      download(
        blob,
        `pogotowie_${date.getDate()}_${date.getMonth() +
          1}_${date.getHours()}_${date.getMinutes()}.json`
      );
    },
  },
};
</script>
