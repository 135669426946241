<template>
  <div>
    <v-alert type="error" v-if="showErr">
      Ojej! Nie mogliśmy wykonać niektórych operacji.
    </v-alert>

    <OnceExercise
      v-if="type == 'once'"
      :title="title"
      :answers="answers"
      :exerciseId="exercise.id"
      :summaryMode="summaryMode"
      :exerciseData="exerciseData"
    />
    <MultipleExercise
      v-if="type == 'multiple'"
      :title="title"
      :answers="answers"
      :exerciseId="exercise.id"
      :summaryMode="summaryMode"
      :exerciseData="exerciseData"
    />
    <InfoToExercise
      v-if="type == 'info'"
      :content="title"
      :answers="answers"
      :summaryMode="summaryMode"
      :exerciseData="exerciseData"
    />
    <OpenExercise
      v-if="type == 'open'"
      :title="title"
      :points="exercise.points"
      :content="title"
      :answers="answers"
      :summaryMode="summaryMode"
      :exerciseData="exerciseData"
    />
  </div>
</template>

<script>
import OnceExercise from "./Exercises/OnceExercise.vue";
import MultipleExercise from "./Exercises/MulitipleExercise.vue";
import InfoToExercise from "./Exercises/InfoToExercise.vue";
import OpenExercise from "./Exercises/OpenExercise.vue";

export default {
  components: { OnceExercise, MultipleExercise, InfoToExercise, OpenExercise },
  props: ["exercise", "summaryMode", "exerciseData", "points"],
  data() {
    return {
      showErr: false,
      type: "",
      title: "",
      answers: [],
    };
  },
  mounted() {
    this.type = this.exercise.exerciseType;
    this.title = this.exercise.exerciseTitle;
    this.answers = this.exercise.exerciseAnswers;
  },
};
</script>

<style>
#wrap {
  display: inline;
}
.exercise-table,
.exercise-table td,
.exercise-table th {
  border: 1px solid;
  font-family: "Times New Roman", Times, serif;
  color: black;
}

.exercise-table td {
  height: 50px;
  padding: 10px;
}

.exercise-table {
  width: 100%;
  border-collapse: collapse;
}

.exercise-table .answer {
  width: 50px;
  text-align: center;
  font-weight: bold;
}
</style>
