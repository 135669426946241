<template>
  <v-card class="mt-3">
    <v-card-title>Wybór jednokrotny</v-card-title>
    <v-card-text>
      <p v-html="title"></p>
      <v-radio-group v-model="radioGroup" v-if="!summaryMode">
        <div v-for="(answer, index) in answers" :key="index">
          <v-radio :value="index">
            <template v-slot:label
              ><span
                style="display: inline-block"
                v-html="answer.content"
              ></span
            ></template>
          </v-radio>
        </div>
      </v-radio-group>

      <div v-else>
        <v-alert v-if="!correct" type="error" text>
          Niepoprawna odpowiedź
        </v-alert>
        <v-alert v-else type="success" text> Poprawna odpowiedź </v-alert>
        <v-radio-group v-model="radioGroup">
          <div v-for="(answer, index) in answers" :key="index">
            <v-radio :value="index" readonly>
              <template v-slot:label
                ><span
                  style="display: inline-block"
                  v-html="answer.content"
                ></span
              ></template>
            </v-radio>
          </div>
        </v-radio-group>
        <v-card color="grey lighten-4" elevation="0">
          <v-card-text>
            <p>Poprawna odpowiedź:</p>
            <p v-if="correctAnswer[0]" v-html="correctAnswer[0].content"></p>
          </v-card-text>
        </v-card>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  props: ["title", "answers", "exerciseId", "summaryMode", "exerciseData"],
  data() {
    return {
      radioGroup: "",
      correctAnswer: {},
      correct: true,
    };
  },
  mounted() {
    function isCorrect(value) {
      return value.isCorrect == true;
    }
    if (this.summaryMode) {
      var filtered = this.answers.filter(isCorrect);
      this.correctAnswer = filtered;

      this.radioGroup = this.exerciseData;

      this.incrementPoints();

      if (this.answers[this.exerciseData] == this.correctAnswer[0]) {
        this.incrementScore();
      } else {
        this.correct = false;
      }
    }
    this.reRenderMath();
  },
  methods: {
    ...mapMutations(["pushExerciseData", "incrementPoints", "incrementScore"]),
    reRenderMath() {
      if (window.MathJax) {
        console.log("rendering mathjax");
        window.MathJax.Hub.Queue(["Typeset", window.MathJax.Hub], () =>
          console.log("done")
        );
      }
    },
  },
  destroyed() {
    // if(this.summaryMode == false) {
    this.pushExerciseData(this.radioGroup);
    // }
  },
};
</script>

<style>
</style>